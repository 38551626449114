import React,{useState} from 'react';
import styled from '@emotion/styled';

import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';

import TextField from './textField/TextField'

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const FormLayout = styled.form`
  width: 50vw;
  height: auto;

  @media screen and (max-width: 1024px){
    &{
      width: 100vw;
    }
  }
`;


const Success = () =>{
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  return(
    <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
    <MuiAlert elevation={6} onClose={handleClose} variant="filled" severity="success">
      Message has been sent!
    </MuiAlert>
  </Snackbar>
  )
}


const InputLayout = styled.div`
  width:100%;
  display: flex;
  justify-content:space-evenly;
  align-items: center;
  margin-top: 2vw;
  margin-bottom: 4vw;

  @media screen and (max-width: 1024px){
    &{

      margin-bottom:8vw;
    }
  }
`;




const FormElement = () =>{
    return(
        <FormLayout
          name="contact v1"
          method="post"
          data-netlify="true"
          onSubmit="submit"
          action={<Success/>}
        >
          <input type="hidden" name="form-name" value="contact v1"/>
          <InputLayout>
            <TextField required type={"text"} pattern="[^0-9]{10}" placeholder={"First Name"} name={"first-name"}/>
            <TextField required type={"text"} pattern="[^0-9]{14}" placeholder={"Last Name"} name={"last-name"}/>
          </InputLayout>
          <InputLayout>
            <TextField required type={"tel"} pattern="[0-9]{10,12}" placeholder={"Phone Number"} name={"phone-number"}/>
            <TextField required type={"email"} placeholder={"Email ID"} name={"email-id"}/>
          </InputLayout>
          <InputLayout>
            <TextField type={"text"} placeholder={"Query"} name={"query-text"}/>
          </InputLayout>
          <InputLayout>
            <Button variant="contained" type="submit" endIcon={<SendIcon/>}>Submit</Button>
          </InputLayout>
        </FormLayout>
    )
} 

export default FormElement;