import React from 'react';

export const Lights = () =>{
        return(
            <>
                <ambientLight intensity={0.5}/>
                <directionalLight position={[0,0,100]} color="white" intensity={1}/>
                <directionalLight position={[-50,0,0]} intensity={1.5}/>
            </>
        )
}
