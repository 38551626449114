import React from 'react';
import styled from '@emotion/styled';

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';


const Link = styled.a`
    text-decoration:none;
    color: red;
    font-size: 2vw;
`;

const Container = styled.div`
    height: 10vh;
    width: 100vw;
    
    position:fixed;
    bottom:0;
    z-index:100;

    display:flex;
    flex-direction:row;
    justify-content:space-evenly;
    align-items:center;
    margin-bottom: 2vh;
`;


const SocialIcons = () =>{
    return(
        <Container>
            <Link href="https://facebook.com/SevenTides/"> <FacebookIcon className="iconsStyles"/> </Link>
            <Link href="https://www.instagram.com/_7ide_/"> <InstagramIcon className="iconsStyles"/> </Link>
            <Link href="https://twitter.com/7ide4"> <TwitterIcon className="iconsStyles"/> </Link>
        </Container>
    )
}

export default SocialIcons;