import React, {useRef} from 'react';
import {useFrame} from 'react-three-fiber';
import {useGLTF} from '@react-three/drei'

import {string} from 'prop-types';


export const Loading = () =>{
    return(
        <mesh>
            <sphereBufferGeometry attach="geometry" args={[1,1,1]}/>
            <meshStandardMaterial attach="material" color="black" wireframe={true}/>
        </mesh>
    )
}


const Mesh = ({color,frame}) =>{
    const {nodes} = useGLTF("models/model1.glb",true);

    const model = useRef(null);


    useFrame(()=>{
        model.current.rotation.y += 0.024;
    })

    return(
        <group>
            <mesh ref={model} geometry={nodes.statueMesh.geometry} attach="geometry" scale={[0.2,0.2,0.2]}>
                <meshStandardMaterial attach="material" color={color} wireframe={(frame === "dev") ? true : false} wireframeLinewidth={1} />
            </mesh>
        </group>
    )
}


Mesh.prototype={
    color: string.isRequired,
    frame: string.isRequired
}


export default Mesh;
