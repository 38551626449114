import React,{useState,useEffect} from "react";
import {SEO, Scene} from '../components/Shared';
import {Layout,Routes,SocialIcons} from '../components/Layout';

import styled from '@emotion/styled';

import {useInView } from 'react-intersection-observer';

import Logo from '../assets/svg/logo.svg';

import tw from 'tailwind.macro';


const H2 = tw.h2`
  text-5xl
`;

export const H2White = styled(H2)`
  color: white;
`;

const H2Black = styled(H2)`
  color: black;
`;
const LogoFile = styled(Logo)`
  width: 50vw;
  height: 50vh;
`


const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display:flex;
  justify-content:center;
  align-items:center;
  position: relative;
  z-index:10;

  scroll-snap-align: center;
`;

const P = tw.p`
 text-3xl
`;

const PBlack = styled(P)`
  color: black;
`;

const PWhite = styled(P)`
  color: white;
`;

const HTMLContent = ({children,bgColor,stateOpen, wireframe,setWireframe}) =>{
  const [refItem,inView] = useInView({
    threshold:0
  })

  useEffect(()=>{
    inView && (document.body.style.background = (bgColor) ? `#000` : `#f2f2f2`) && ((bgColor) ? stateOpen(true) : stateOpen(false))
  },[inView,bgColor,stateOpen]);
  
  useEffect(()=>{
    inView && ((wireframe) ? setWireframe(true) : setWireframe(false))
  },[inView,wireframe,setWireframe])


  return(
    <Container>
      <div ref={refItem}>
        {children}
      </div>
    </Container>
  )
  
}

const IndexPage = () => {
  const [state,stateOpen] = useState(false);
  const [wire, setWireframe] = useState(false);
  return(
    <>
    <SEO title="Home" desc={"7IDE is a team of designers, markerters, developers, and engineers, working together on various domains from designing, branding, software development and more to help businesses grow."}>
    <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
          "@type": "Corporation",
          "name": "7IDE",
          "url": "https://7ide.co.in/",
          "logo": "https://7ide.co.in/icons/icon-192x192.png?v=97c706588e973d6567d9aff02fab93c9",
          "sameAs": [
            "https://www.facebook.com/SevenTides/",
            "https://twitter.com/7ide4",
            "https://www.instagram.com/_7ide_/",
            "https://github.com/7ide",
            "https://7ide.co.in/"
          ]
        }`}
    </script>

    </SEO>
    <Routes/>
    <SocialIcons/>
    <Layout>
      <HTMLContent bgColor={true} stateOpen={stateOpen} wireframe={true} setWireframe={setWireframe}><LogoFile/></HTMLContent>
      <HTMLContent bgColor={false} stateOpen={stateOpen} wireframe={true} setWireframe={setWireframe}>
        <H2Black>Who are we?</H2Black>
        <PBlack>We are the rising tide that lifts all your ideas!<br/> Specialising in Design, Art &amp; Technology</PBlack>  
      </HTMLContent>
      <HTMLContent bgColor={true} stateOpen={stateOpen} wireframe={true} setWireframe={setWireframe}>
        <H2White>What we do?</H2White>
        <PWhite>Sprinkling the essense of life into our clients brands!</PWhite>
      </HTMLContent>
      <HTMLContent bgColor={true} stateOpen={stateOpen} wireframe={false} setWireframe={setWireframe}>
        <H2White>Why us?</H2White>
        <PWhite>To Explore &amp; Enhance your Experience.</PWhite>  
      </HTMLContent>
      <Scene state={state} wire={wire}/>
    </Layout>
  </>
  )
}

export default IndexPage
