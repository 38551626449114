import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import styled from '@emotion/styled';

import tw from 'tailwind.macro';


const AniLinkStyled = styled(AniLink)`
  ${tw`text-white`};
  text-decoration: none;
`

const Navigation = styled.div`
    width: 100vw;
    height: 10vh;
    background: rgb(0,0,0,0.25);
    display: flex;
    justify-content: space-evenly;
    align-items:center;
    position:fixed;
    top:0;
    z-index:100;
`;

const Routes = ({state}) =>{
    return(
        <>
         <Navigation>
             <AniLinkStyled cover direction="right" to="/" bg="white">/experience</AniLinkStyled>
             <AniLinkStyled paintDrip to="/" hex="#f2f2f2"><h1 itemType="https://schema.org/Organization"><span itemProp="name">7IDE</span></h1></AniLinkStyled>
             <AniLinkStyled paintDrip to="/enquiry" hex="#f2f2f2" >/enquiry</AniLinkStyled>
         </Navigation>
        </>
    )
}



export default Routes;