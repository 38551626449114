import React, {Suspense} from 'react';
import {Canvas} from 'react-three-fiber';

import {Lights} from './lights/Lights'

import Mesh,{Loading} from './layers/Mesh'

import styled from '@emotion/styled';

import {bool} from 'prop-types';

const CanvasContainer = styled.div`
    position:fixed;
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
    z-index:0;
`;


const Scene = ({state,wire}) =>{
    return(
        <CanvasContainer>
            <Canvas>
                <Lights/>
                <Suspense fallback={<Loading/>}>
                   <Mesh color={(state) ? `black` : `white`} frame={(wire) ? `none` : `dev`}/>
                </Suspense>
            </Canvas>
        </CanvasContainer>
    )
}


Scene.propTypes = {
    state: bool.isRequired,
    wire: bool.isRequired
}



export default Scene;