import React from 'react';
import {Helmet} from 'react-helmet';
import {string} from 'prop-types';


const SEO = ({title,desc,children}) =>{
    return(
        <>
            <Helmet>
            ${/*basic meta*/}
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <meta http-equiv="X-UA-Compatible" content="ie=edge"/>
            <meta name="keywords" content="7ide, design, branding, web development, websites, marketing"/>

            ${/* Author, Description meta*/}
            <meta name="author" content="Vaishakh Anil (@_vaishakhanil_)"/>
            <meta name="description" content={desc}/>

            ${/*Twitter SEO*/}
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@7ide4"/>
            <meta name="twitter:creator" content="@_vaishakhanil_"/>

            ${/*Facebook SEO*/}
            <meta property = "og:site_name" content="7IDE"/>
            <meta property = "og:title" content="7IDE"/>
            <meta property = "og:type" content="website"/>
            <meta property = "og:url" content="https://7ide.co.in"/>
            <meta property = "og:description" content="7IDE is a team of designers, markerters, developers, and engineers, working together on various domains from designing, branding, software development and more to help businesses grow."/>
            <meta property="og:image" itemprop="image primaryImageOfPage" content="https://res.cloudinary.com/design-7ide/image/upload/v1614919753/logo_wl4nxv.png" />
            <meta property="og:image:alt" content="7IDE"/>

            ${children}
                
            
            <title>7IDE | {title}</title>
            </Helmet>
        </>
    )
}


SEO.propType = {
    title: string.isRequired,
    desc: string.isRequired,
}

export default SEO;