import React,{useState} from 'react';
import styled from '@emotion/styled';

const InputField = styled.input`
width: 20vw;
height: 5vh;
background: rgba(0,0,0,0.1);
border: none;
border-bottom: 0.25rem solid white;
border-radius: 2px;

color: white;
font-size: 1rem;

transition: all 250ms ease-in-out;

&::placeholder{
  font-size: 1rem;
  text-align:center;
}
&:active{
  border-bottom: 0.25rem solid blue;
}

@media screen and (max-width: 1024px){
  width: 40vw;
  font-size: 1rem;
}

`;


const TextField = ({placeholder,type, name}) =>{

    return(
        <>
        <InputField type={type} placeholder={placeholder} name={name}/>
        </>
    )
}

export default TextField;